<script setup lang="ts">
// Properties and events.
//
const props = defineProps<{
  path?: any
  link?: boolean
  styleOpts?: string
  baseStyle?: string
  readonly?: boolean
  title?: string
}>()
// Main variables.
//
const title = toRef(props, 'title')
const readonly = toRef(props, 'readonly')
const path = toRef(props, 'path')
const link = toRef(props, 'link')
const styleOpts = toRef(props, 'styleOpts')
const baseStyle = toRef(props, 'baseStyle')
const setTitle = title.value ? ref(title.value) : ref('')
// Functions.
//
const processClick = defineEmits<{
  (event: 'process:click'): void
}>()
const styleEditor = function styleEditor(style: string) {
  let output = ''
  switch (style) {
    case 'light':
      output = 'rk-btn--light'
      break
    case 'dark':
      output = 'rk-btn--dark'
      break
    case 'red':
      output = 'rk-btn--red'
      break
    case 'orange':
      output = 'rk-btn--orange'
      break
    case 'secondary':
      output = 'rk-btn--green'
      break
    case 'plain':
      output = 'rk-btn--plain'
      break
    case 'accordion':
      output = 'rk-btn--accordion'
      break
    case 'transparent':
      output = 'rk-btn--transparent'
      break
  }
  return output
}
const style = baseStyle.value ? ref(styleEditor(baseStyle.value)) : ref('')
</script>

<template>
  <nuxt-link
    v-if="link && path"
    :to="path"
    :disabled="readonly"
    class="rk-btn"
    :class="[styleOpts, style]"
    @click="processClick('process:click')"
  >
    <slot></slot>
  </nuxt-link>
  <button
    v-else
    :title="setTitle"
    class="rk-btn"
    :disabled="readonly"
    :class="[styleOpts, style]"
    @click="processClick('process:click')"
  >
    <slot></slot>
  </button>
</template>

<style lang="scss" scoped>
@import '/assets/scss/template/components/button';
</style>
